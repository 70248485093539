var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// ----------------------------------------------------------------------
export default function Tabs(theme) {
    return {
        MuiTabs: {
            defaultProps: {
                textColor: 'inherit',
                allowScrollButtonsMobile: true,
                variant: 'scrollable',
            },
            styleOverrides: {
                scrollButtons: {
                    width: 48,
                    borderRadius: '50%',
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
                iconPosition: 'start',
            },
            styleOverrides: {
                root: function (_a) {
                    var _b;
                    var ownerState = _a.ownerState;
                    return (__assign({ padding: 0, opacity: 1, minWidth: 48, fontWeight: theme.typography.fontWeightMedium, '&:not(:last-of-type)': (_b = {
                                marginRight: theme.spacing(3)
                            },
                            _b[theme.breakpoints.up('sm')] = {
                                marginRight: theme.spacing(5),
                            },
                            _b), '&:not(.Mui-selected)': {
                            color: theme.palette.text.secondary,
                        } }, ((ownerState.iconPosition === 'start' || ownerState.iconPosition === 'end') && {
                        minHeight: 48,
                    })));
                },
            },
        },
    };
}
